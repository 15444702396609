// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cruise-js": () => import("./../../../src/pages/cruise.js" /* webpackChunkName: "component---src-pages-cruise-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-goldee-js": () => import("./../../../src/pages/goldee.js" /* webpackChunkName: "component---src-pages-goldee-js" */),
  "component---src-pages-hurleygigiitsy-js": () => import("./../../../src/pages/hurleygigiitsy.js" /* webpackChunkName: "component---src-pages-hurleygigiitsy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lucy-js": () => import("./../../../src/pages/lucy.js" /* webpackChunkName: "component---src-pages-lucy-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-zac-js": () => import("./../../../src/pages/zac.js" /* webpackChunkName: "component---src-pages-zac-js" */),
  "component---src-pages-zoe-js": () => import("./../../../src/pages/zoe.js" /* webpackChunkName: "component---src-pages-zoe-js" */)
}

